@import '_variables.scss';

.Header {
  background-color: $green-color;
  padding: 0 20px;
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;

  .Header__title {
    font-size: 24px;
    color: $white-color;
  }

  .Header__action {
    display: flex;
    align-items: center;

    .loginBtn {
      width: 40px;
      height: 30px;
      text-align: center;
      background-color: rgb(58, 120, 99);
      border-radius: 6px;
      padding: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      margin-right: 6px;
    }

    .adm-image {
      margin-left: 6px;
    }
  }
}

.rightMenuBox {
  .rightMenuBox__logo {
    background-color: $green-color;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
  }

  .rightMenuBox__list {
    background-color: $white-color;
    padding-top: 40px;
    padding-right: 12px;

    .rightMenuBox__list__row {
      border-radius: 0 15px 15px 0;
      padding-left: 32px;
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 16px;
      color: $black-color;

      .adm-image {
        margin-right: 12px;
      }
    }
  }
}