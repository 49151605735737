$white-color: #FFFFFF;
$green-color: rgb(36, 105, 82);
$green1-color: rgb(58, 120, 99);
$green2-color: rgb(31, 158, 115);
$black-color: #000;
$blackFont-color: rgb(31, 31, 31);
$blackFont1-color: rgb(16, 66, 49);
$ash-color: rgb(242, 241, 237);
$ashBorder-color: rgb(218, 217, 213);
$cardBG-color: rgb(248, 248, 248);
$yellow-color: rgb(251, 81, 44);

.white-color{
  color: $white-color;
}
.green-color{
  background-color: $green-color;
}
.yellow-color{
  color: $yellow-color;
}
.green2-color{
  color: $green2-color;
}
.font136-color{
  color: rgb(136, 136, 136);
}
.underline {
  text-decoration-thickness: 1px;
  text-decoration-line: underline;
}

.break__spaces{
  white-space: pre-wrap;
}

.mt2{
  margin-top: 2px;
}

.mt8{
  margin-top: 8px;
}
.mt12{
  margin-top: 12px;
}
.mt16{
  margin-top: 16px;
}

.mt32{
  margin-top: 32px;
}
.mb4{
  margin-bottom: 4px;
}
.mb8{
  margin-bottom: 8px;
}
.mb16{
  margin-bottom: 16px;
}
.mr4{
  margin-right: 4px;
}
.ml8{
  margin-left: 8px;
}
.m80{
  margin: 8px 0;
}
.font14{
  font-size: 14px;
}

.font16{
  font-size: 16px;
}
.font18{
  font-size: 18px;
}
.font20{
  font-size: 20px;
}
.font24{
  font-size: 24px;
}

.font__bold6{
  font-weight: 600;
}
.font__bold{
  font-weight: 700;
}

.card__box {
  margin-top: 16px;
  background-color: $cardBG-color;
  border-radius: 8px;
  padding: 0 20px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.1) 0px 2px 4px -2px
}

.whitespace__nowrap {
  white-space: nowrap;
}

.padding20{
  padding: 20px;
}
.paddingNoT20{
  padding: 0 20px 20px 20px;
}
.padding016{
  padding: 0 16px;
}
.paddingb20{
  padding-bottom: 20px;
}
.paddingt12{
  padding-top: 12px;
}
.paddingl16{
  padding-left: 16px;
}
.flex__space__between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.aling__center{
  display: flex;
  align-items: center;
}
.flex__space__center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex__space__end{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex__direction{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text__right{
  text-align: right;
}
.text__center{
  text-align: center;
}
.text__over{
  text-overflow: ellipsis;
  text-wrap: nowrap;
  white-space-collapse: collapse;
}
.text__hidden{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.border__r50{
  border-radius: 50px;
}




// @import 'utils/variables';