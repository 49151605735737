.modal__ok {
  background: rgb(36, 105, 82);
  color: rgb(255, 255, 255);
  border-radius: 40px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-top: 8px;
}


.tableList__Box{
  width: -webkit-fill-available;
  border-spacing: 0;
  thead{
    tr{
      height: 40px;
    }
  }
  tbody{
    tr{
      height: 40px;
    }
    tr:nth-child(2n+1) {
      background-color: rgb(242, 241, 237);;
    }
    
  }
}