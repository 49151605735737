.Login {
  background-color: rgb(242, 241, 237);
  min-height: 100vh;
  padding: 20px;

  .adm-list-item {
    background-color: rgb(242, 241, 237);
    .send__input.adm-input{
      border-right: none;
    }
    .adm-input {
      width: 100%;
      border: 1px solid;
      height: 50px;
      padding: 0 20px;
    }
  }
  .code__box{
    .adm-list-item-content{
      display: flex;
      align-items: normal;
      justify-content: none;
    }
  }
  .send__btn{
    height: 50px;
    border-top: 1px solid;
    border-radius: 0;
    border-right: 1px solid;
    padding: 0;
    border-bottom: 1px solid;
    width: max-content;
  }
  .forgotLink{
    background-color: rgb(242, 241, 237);
    width: 100%;
    text-align: right;
    color: royalblue;
    padding-right: 16px;
    font-size: 13px;
  }
  .adm-form-item-label{
    height: 22px;
  }
  .adm-list-body-inner{
    background-color: rgb(242, 241, 237);
  }
  .adm-checkbox{
    background-color: rgb(242, 241, 237);
    padding: 0 14px;
    .adm-checkbox-content{
      font-size: 13px;
    }
  }
  .adm-checkbox .adm-checkbox-icon{
    border-radius: 0;
  }
  .Password__font{
    font-size: 13px;
    background-color: rgb(242, 241, 237);
    text-align: center;
  }
  .adm-list-item-content-extra{
    flex: auto;
      padding: 12px 0;
  }
  .password__top{
    height: 22px;
    margin-bottom: 4px;
  }
}