@import '_variables.scss';
.ManageDetail{
  background-color: $white-color;
  .adm-tab-bar{
    .adm-tab-bar-wrap{
      .adm-tab-bar-item{
        height: 64px;
        .adm-tab-bar-item-title{
          font-size: 18px;
          font-weight: 600;
          color: rgb(31, 31, 31);
          line-height: 21px;
        }
      }
      .adm-tab-bar-item-active{
        .adm-tab-bar-item-title{
          color: $green-color;
        }
        
      }
    }
  }
  .allWithdraw {
    font-size: 15px;
    font-weight: 700;
    color: $green-color;
    line-height: 20px;
  }
}