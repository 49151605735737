.WalletNet__bot {
  background-color: #fff;
  padding: 24px 20px;
  .title{
    font-size: 20px;
    font-weight: 600;
    color: rgb(31, 31, 31);
    line-height: 23px;
    margin-bottom: 13px;
  }
  .list__usdt{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 16px 0px;
    border-bottom: 1px solid rgba(31, 31, 31, 0.04);
    .list__left{
      display: flex;
      align-items: center;
      span{
        margin-left: 10px;
        color: #000;
      }
    }
    .list__right{
      text-align: right;
      div{
        font-size: 16px;
        font-weight: bold;
        color: rgb(31, 31, 31);
        line-height: 19px;
        margin-bottom: 2px;
      }
      span{
        font-size: 14px;
        font-weight: 400;
        color: rgb(31, 31, 31);
        line-height: 14px;
      }
    }
  }
}