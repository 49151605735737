@import '_variables.scss';

.Home {
  background-color: $green-color;

  .Mid {
    padding: 0 20px;

    .Swiper__indicator {
      .adm-page-indicator-dot {
        width: 16px;
        margin: 0 4px;
      }

      .adm-page-indicator-dot.adm-page-indicator-dot-active {
        width: 24px;
      }
    }

    .Swiper__box {
      height: 334px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      vertical-align: bottom;
      text-align: center;
      padding-top: 200px;

      .fontW700 {
        font-weight: 700;
        font-size: 20px;
      }

      .fontS14 {
        margin-top: 8px;
        font-size: 14px;
      }

      .btn__box {
        display: block;
        width: 180px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        border-radius: 18px;
        color: $white-color;
        margin: 12px auto;
      }
    }

    .Swiper__bottom {
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;

      .Swiper__bottom__action {
        width: 72px;
        height: 72px;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        color: $white-color;
        background-color: $green1-color;
        align-items: center;
        padding-top: 16px;

        .Swiper__bottom__font {
          margin-top: 4px;
        }
      }
    }
  }

  .Bottom {
    background-color: $ash-color;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    .Bottom__box{
      padding: 16px;
      position: relative;
      .leftEchartsLine{
        position: absolute;
        font-size: 40px;
        top: 35%;
        left: -15px;
        color: $green-color;
      }
      .rightEchartsLine{
        position: absolute;
        font-size: 40px;
        top: 35%;
        right: -15px;;
        color: $green-color;
      }
      .Bottom__title{
        font-size: 14px;
        font-weight: 700;
        color: $blackFont-color;
      }

      .Slider__name{
        --fill-color: #1f9e73;
        .adm-slider-fill{
          height: 6px;
        }
        .adm-slider-track{
          height: 6px;
        }
        .adm-slider-thumb-container{
          width: 18px;
          height: 18px;
        }
        .adm-slider-thumb{
          width: 18px;
          height: 18px;
          border: 2px solid #1f9e73;
          margin: 0;
        }
      }
      .Bottom__box__title{
        font-size: 14px;
        font-weight: 700;
        color: $black-color;
        margin: 18px 0 8px 0;
      }
      .Bottom__tit{
        font-size: 14px;
        font-weight: 700;
        color: $black-color;
      }
      .Bottom__usdt{
        font-size: 14px;
        color: $green2-color;
      }
    }

    .link__a {
      display: flex;
      align-items: center;
      color: $blackFont-color;
      justify-items: center;
      line-height: 20px;

      .adm-image {
        display: inline-block;
        margin-right: 4px;
      }
    }

    .Defi__box {
      .Defi__box__Polyline {
        width: 160px;

        .Defi__box__Polyline__Img {
          margin-right: 8px;
          width: 52px;
          height: 32px;
          position: relative;

          .Defi__box__Polyline__Img1 {
            position: absolute;
            right: 0;
            top: 5px;
          }

          .Defi__box__Polyline__Img2 {
            position: absolute;
            right: 7px;
            top: 5px;
          }

          .Defi__box__Polyline__Img3 {
            position: absolute;
            right: 14px;
            top: 5px;
          }

          .Defi__box__Polyline__Img0 {
            position: absolute;
            right: 21px;
            z-index: 1;
          }
        }

        .adm-image {
          display: inline-block;
        }

        .Defi__box__title {
          width: 100px;
          font-size: 14px;
          font-weight: 700;
          color: $blackFont-color;
        }

        .Defi__box__key {
          align-items: center;
          display: flex;
          width: 100%;
        }
      }

      .Defi__APY{
        margin-bottom: 20px;
        .Defi__APY__key {
          font-size: 24px;
          font-weight: 700;
          color: $green2-color;
        }
  
        .Defi__APY__value {
          font-size: 18px;
          font-weight: 700;
          color: $blackFont-color;
        }
      }

      .Defi__TVL {
        font-size: 14px;
        color: $blackFont-color;
        font-weight: 700;
      }
    }

    .AI__box {
      display: flex;
      align-items: center;
      padding-bottom: 20px;

      .AI__title {
        margin-left: 8px;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .AI__key {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // .adm-image{
      //   display: inline-block;
      // }
      .AI__key__name {
        font-size: 12px;
        color: $black-color;
        display: flex;
        align-items: center;
      }

      .AI__key__value {
        font-weight: 700;
        color: $green-color;
      }
    }

    .aboutUs {
      height: 168px;
      width: 100%;
      border-radius: 16px 16px 0 0;
      background-image: url('../../assets/images/about_background.png');
      background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .aboutUs__logo{

      }
    }

    .announcement {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .announcement__box {
        margin-top: 8px;
      }

      .goAnnouncement__detail {
        transform: rotate(-90deg);
      }
    }
  }
  .adm-floating-bubble-button{
    z-index: 999;
  }
}