.AllAward{
  min-height: 100vh;
  background-color: rgb(242, 241, 237);
  .AllAward__wrap{
    padding: 20px;
    .AllAward__card{
      padding: 20px;
      position: relative;
      border-radius: 16px;
      background-image: linear-gradient(rgb(254, 198, 165), rgb(255, 228, 213));
      .AllAward__absolute{
        position: absolute;
        right: 0px;
        top: 20px;
        font-size: 15px;
        font-weight: 600;
        color: rgb(91, 47, 34);
        line-height: 15px;
        padding: 8px 16px;
        border-radius: 40px 0px 0px 40px;
        background: rgb(238, 188, 161);
      }
    }
    .min__card{
      text-align: center;
      background-color: #fff;
      border-radius: 16px;
      width: 48%;
      padding: 20px;
    }
  }
}