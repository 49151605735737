@import '_variables.scss';
.ManageMoney{
  background-color: $green-color;
  .ManageMoney__greenBox{
    padding: 0 20px 24px 20px;
    .ManageMoney__imgBox{
      width: 100%;
      height: 140px;
      border-radius: 12px;
      margin-bottom: 16px;
    }
    .bgGreenAsh__box{
      height: 114px;
      background: rgb(58, 120, 99);
      border-radius: 12px;
      color: $white-color;
      width: 160px;
      padding-top: 16px;
      .marginBottom{
        margin-bottom: 4px;
      }
      .flex__space__center{
        margin-top: 12px;
        font-size: 14px;
        font-weight: 700;
        .imgClass{
          margin-left: 4px;
        }
      }
    }
    .left__box{
      width: 160px;
      margin-right: 8px;
    }

    .monitor__box{
      color: $white-color;
      background: rgb(58, 120, 99);
      border-radius: 12px;
      width: 160px;
      padding-top: 12px;
      text-align: center;
      height: 114px;
      // display: flex;
      // align-items: center;
      span{
        margin-left: 8px;
      }
    }
  }

  .ManageMoney__bottom{
    background-color: rgb(242, 241, 237);
    border-radius: 16px 16px 0 0;
    min-height: 100vh;
    color: rgb(31, 31, 31);
    padding: 24px 20px 40px 20px;
    .bot__title{
      font-weight: 700;
      margin-bottom: 16px;
      font-size: 20px;
    }
    .card__box{
      background-color: $white-color;
      padding: 24px 16px;
      .AI__box {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
  
        .AI__title {
          margin-left: 8px;
          font-size: 18px;
          font-weight: 700;
        }
      }
  
      .AI__key {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        // .adm-image{
        //   display: inline-block;
        // }
        .AI__key__name {
          font-size: 12px;
          color: $black-color;
          display: flex;
          align-items: center;
        }
  
        .AI__key__value {
          font-weight: 700;
          color: $green-color;
        }
      }
      
    }
  }

  .title__font {
    font-size: 15px;
    font-weight: 400;
  }

    .adm-list-item {
      background-color: $cardBG-color;

      .adm-input-element {
        font-size: 18px;
        font-weight: 700;
        color: $black-color;
      }
    }

    .form__USDT {
      color: $black-color;

      .allWithdraw {
        font-size: 15px;
        font-weight: 700;
        color: $green-color;
        line-height: 20px;
      }
    }

    .adm-list-body {
      border: none;
    }

}