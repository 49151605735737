@import '_variables.scss';
.DepositAddress{
  background-color: $ash-color;
  .DepositAddress__content{
    padding: 20px;
    .card__box{
      padding: 20px;
      background-color: $white-color;
      .list__left{
        display: flex;
        align-items: center;
        span{
          margin-left: 10px;
          color: rgb(129, 129, 129);
        }
        .flex__direction{
          .USDT{
            font-size: 16px;
            font-weight: 600;
            color: $blackFont-color;
            line-height: 19px;
            margin-bottom: 2px;
          }
        }
      }
      .card__box__title{
        margin: 10px 0;
      }
      .card__box__input{
        background-color: $cardBG-color;
        line-height: 40px;
        padding: 0 16px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 6px;
        .rightBlack{
          transform: rotate(180deg);
        }
        .adm-list-item{
          background-color: $cardBG-color;
          .adm-input-element{
            font-size: 18px;
            font-weight: 700;
            color: $black-color;
          }
        }
        .form__USDT{
          color: $black-color;
        }
        .url__qrcode{
          overflow-wrap: break-word;
          .adm-button{
            background-color: $cardBG-color;
            border: none;
          }
        }
      }
      .QRCode__box{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
      }
      .qrcode__btn{
        font-weight: 500;
        color: rgb(255, 255, 255);
        font-size: 16px;
        width: 100%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        border-radius: 40px;
        background-color: rgb(65, 170, 135);
        margin-top: 16px;
      }
      .save__btn{
        background-color: $green-color;
      }
    }
  }
  .adm-list-item {
    background-color: $cardBG-color;

    .adm-input-element {
      font-size: 18px;
      font-weight: 700;
      color: $black-color;
    }
  }

  .form__USDT {
    color: $black-color;
    font-weight: 700;

    .allWithdraw {
      font-size: 15px;
      font-weight: 700;
      color: $green-color;
      line-height: 20px;
    }
  }

  .adm-list-body {
    border: none;
  }
  .adm-list-item-content{
    border: none;
  }
}