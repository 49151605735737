@import '_variables.scss';

.OnlineWithdraw {
  .OnlineWithdraw__box {
    padding: 20px;

    .list__left {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
        color: rgb(129, 129, 129);
      }

      .flex__direction {
        .USDT {
          font-size: 16px;
          font-weight: 600;
          color: $blackFont-color;
          line-height: 19px;
          margin-bottom: 2px;
        }
      }
    }

    .card__box {
      border: 1px solid rgb(238, 238, 238);
      padding: 20px;
      background-color: $white-color;

      .title__font {
        font-size: 15px;
        font-weight: 400;
        color: rgb(129, 129, 129);
      }

      .card__box__input {
        line-height: 40px;
        padding: 4px 11px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 6px;

        .adm-list-item {
          background-color: $cardBG-color;

          .adm-input-element {
            font-size: 18px;
            font-weight: 700;
            color: $black-color;
          }
        }

        .form__USDT {
          color: $black-color;

          .allWithdraw {
            font-size: 15px;
            font-family: "PingFang SC-Medium", "PingFang SC";
            font-weight: 500;
            color: rgb(247, 147, 26);
            line-height: 20px;
          }
        }

        .adm-list-body {
          border: none;
        }

        .Withdraw__message{
          margin-bottom: 10px;
          text-align: center;
          font-size: 14px;
          color: rgb(31, 31, 31);
          line-height: 14px;
          .Withdrawnum{
            font-weight: 600;
          }
          .Withdrawfee{
            color: rgb(131, 131, 131);
            font-weight: 500;
          }
        }
      }

      .cardBG {
        background-color: $cardBG-color;
      }
    }
  }
}
.withdrawInput__box{
  .adm-input{
    width: 80%;
    height: 50px;
    border: 1px solid;
    margin: 0 auto;
  }
}