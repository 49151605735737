.Lottery{
    // background-position: 50% 50%;
    background-color: rgb(242, 241, 237);
  .Lottery__box{
    padding: 20px;
    min-height: 100vh;

    .Slider__name{
      --fill-color: #1f9e73;
      .adm-slider-fill{
        height: 6px;
      }
      .adm-slider-track{
        height: 6px;
      }
      .adm-slider-thumb-container{
        width: 18px;
        height: 18px;
      }
      .adm-slider-thumb{
        width: 18px;
        height: 18px;
        border: 2px solid #1f9e73;
        margin: 0;
      }
    }

    .Lottery__btn{
      margin-top: 20px;
      background-color: rgb(36, 105, 82);
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      border-radius: 50px;
    }
  }
}