@import '_variables.scss';

.TeamDetail{
  .TeamDetail__wrap{
    padding: 20px;
    .TeamDetail__card{
      background-image: linear-gradient(rgb(254, 198, 165), rgb(255, 228, 213));
      border-radius: 8px;
      padding: 20px 40px;
      .card__title{
        font-size: 12px;
      }
      .card__value{
        font-size: 24px;
        font-weight: 700;
      }
      .card__big{
        margin-top: 12px;
        .flex__direction{
          width: 100%;
          .card__value{
            font-size: 14px;
          }
        }
      }
    }
    .adm-tab-bar{
      .adm-tab-bar-wrap{
        .adm-tab-bar-item{
          height: 64px;
          .adm-tab-bar-item-title{
            font-size: 18px;
            font-weight: 600;
            color: rgb(31, 31, 31);
            line-height: 21px;
          }
        }
        .adm-tab-bar-item-active{
          .adm-tab-bar-item-title{
            color: $green-color;
          }
          
        }
      }
    }

    .Hierarchy__box{
      border: 1px solid $ash-color;
      border-radius: 8px;
      margin: 10px 0;
      padding-left: 20px;
      line-height: 40px;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
.PopoverClassSet{
  .adm-popover-menu-list-inner{
    max-height: 500px;
    overflow-y: scroll;
    .adm-popover-menu-item-text{
      width: 200px;
    }
  }
}