@import '_variables.scss';
.CloudComputed{
  background-color: $green-color;
  .CloudComputed__greenBox{
    padding: 0 20px 24px 20px;
    .CloudComputed__imgBox{
      width: 100%;
      // height: 140px;
      background-color: $white-color;
      border-radius: 12px;
      padding: 24px 28px 20px 20px;
      margin-bottom: 16px;
      .imgBox__left{
        height: 116px;
        .imgBox__left__bold{
          font-weight: 700;
          font-size: 20px;
          color: rgb(16, 66, 49);
          margin: 4px 0 12px 0;
        }
        .imgBox__left__btn{
          width: 96px;
          height: 28px;
          border-radius: 12px;
          background-color: $green-color;
          text-align: center;
          line-height: 28px;
          color: $white-color;
        }
      }
    }
    .bgGreenAsh__box{
      height: 114px;
      background: rgb(58, 120, 99);
      border-radius: 12px;
      color: $white-color;
      width: 48%;
      padding-top: 16px;
      .marginBottom{
        margin-bottom: 4px;
      }
      .CloudComputed__num{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 8px;
        overflow-wrap: break-word;
      }
      .flex__space__center{
        margin-top: 12px;
        font-size: 14px;
        font-weight: 700;
        .imgClass{
          margin-left: 4px;
        }
      }
    }

    .monitor__box{
      color: $white-color;
      background: rgb(58, 120, 99);
      border-radius: 12px;
      width: 112px;
      padding-left: 12px;
      height: 50px;
      display: flex;
      align-items: center;
      span{
        margin-left: 8px;
      }
    }
  }

  .CloudComputed__bottom{
    background-color: rgb(242, 241, 237);
    border-radius: 16px 16px 0 0;
    color: rgb(31, 31, 31);
    padding: 24px 20px 40px 20px;
    .bot__title{
      font-weight: 700;
      margin-bottom: 16px;
      font-size: 20px;
    }
    .card__box{
      background-color: $white-color;
      padding: 8px 0 16px 0;

      .card__title{
        line-height: 32px;
        border-bottom: 1px solid $ash-color;
        padding: 0 16px;
      }
      .card__content{
        margin-top: 16px;
        padding: 0 16px;
        .card__BTC{
          display: flex;
          align-items: center;
          span{
            font-size: 24px;
            margin-left: 8px;
          }
        }
        .content__list{
          font-size: 14px;
          .title__font{
            color: rgb(131, 131, 131);
            margin-bottom: 6px;
          }
          .font__bold{
            margin-bottom: 8px;
          }
        }
        .content__btn{
          margin-top: 12px;
          font-size: 16px;
          .nowBtn{
            color: $white-color;
            line-height: 40px;
            border-radius: 16px;
            text-align: center;
            width: 100px;
            background-color: $black-color;
          }
        }
      }
    }
  }
}