@import '_variables.scss';
.WrapTitle{
  margin-top: 20px;
  .WrapTitle__title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleFont{
      font-weight: 700;
      font-size: 20px;
      color: $black-color;
    }
    .moreDetail{
      font-weight: 700;
      font-size: 14px;
      color: $black-color;
      border: 1px solid $ashBorder-color;
      border-radius: 20px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .rightBlack{
        margin-left: 3px;
      }
    }
  }
}