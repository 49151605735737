@import '_variables.scss';

.WalletNet {
  background-color: $green-color;

  .WalletNet__top {
    padding: 0 20px;

    .WalletNet__top__card {
      padding: 20px 16px 0 16px;
      background-image: linear-gradient(rgb(207, 248, 234) 0%, rgb(255, 255, 255) 100%);
      border-radius: 16px;

      .card__title {
        font-size: 14px;
        font-weight: 400;
        color: $blackFont1-color;
        line-height: 14px;
        margin-bottom: 4px;
      }

      .card__blance {
        font-size: 26px;
        font-weight: bold;
        color: $blackFont1-color;
        line-height: 30px;
        margin-bottom: 5px;

        span {
          font-size: 20px;
        }
      }

      .card__icome {
        font-size: 15px;
        font-weight: 400;
        color: $blackFont1-color;
        line-height: 15px;
      }

      .card__icome__blance {
        font-size: 14px;
        font-weight: bold;
        color: $blackFont1-color;
        line-height: 16px;
      }

      .bannerActionBox {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;

        .bannerAction {
          display: flex;
          align-items: center;
          margin-top: 13px;

          .bannerAction__btn {
            height: 69px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: $blackFont1-color;
            line-height: 19px;

            .adm-image {
              margin: 11px 25px 0 25px;
            }
          }
        }
      }
    }
  }

  .WalletNet__bot {
    background-color: $white-color;
    height: calc(-318px + 100vh);
    padding: 24px 20px;
    border-radius: 24px 24px 0px 0px;
    margin-top: 24px;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: $blackFont-color;
      line-height: 23px;
      margin-bottom: 13px;
    }

    .list__usdt {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding: 16px 0px;
      border-bottom: 1px solid rgba(31, 31, 31, 0.04);

      .list__left {
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
          color: $black-color;
        }
      }

      .list__right {
        text-align: right;

        div {
          font-size: 16px;
          font-weight: bold;
          color: rgb(31, 31, 31);
          line-height: 19px;
          margin-bottom: 2px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
          color: rgb(31, 31, 31);
          line-height: 14px;
        }
      }
    }
  }
}