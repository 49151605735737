@import '_variables.scss';
.InviteFriend{
  background-color: $green-color;
  .InviteFriend__topbox{
    background-image: linear-gradient(rgb(207, 248, 234) 0%, rgb(255, 255, 255) 64%);
    padding: 20px;
    border-radius: 16px;
    margin: 0 20px 20px 20px;
    .top__title{
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: rgb(16, 66, 49);
      line-height: 23px;
      font-size: 20px;
    }
    .now__lev{
      .now__lev__rule{
        display: flex;
        align-items: center;
        .now__rule__lev{
          font-size: 40px;
          font-weight: 800;
          margin-right: 10px;
        }
        .now__rule__rule{
          color: $blackFont1-color;
          font-weight: 600;
          font-size: 14px;
          margin-right: 8px;

        }
        .now__rule__next{
          font-size: 14px;
          font-weight: 400;
          color: $blackFont1-color;
          line-height: 14px;
          opacity: 0.7;
        }
      }
    }
    .now__lev__title{
      padding: 4px 10px;
      font-size: 14px;
      font-weight: 600;
      color: $white-color;
      border-radius: 40px;
      width: max-content;
      // height: 14px;
      line-height: 14px;
      background-image: linear-gradient(85deg, rgb(16, 66, 49) 0%, rgba(122, 159, 146, 0.7) 100%);
    }
    .next__lev{
      .now__lev__title{
        margin-bottom: 12px;
      }
      .next__lev__box{
        margin-bottom: 12px;
      }
      .next__lev__title{
        font-size: 14px;
        font-weight: 600;
        color: $blackFont1-color;
        line-height: 16px;
      }
      .next__lev__num{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: $blackFont1-color;
        line-height: 14px;
        .next__lev__usdt{
          margin-left: 5px;
        }
      }
    }

    .look__team{
      color: $white-color;
      font-size: 16px;
      font-weight: 600;
      border-radius: 50px;
      width: 100%;
      text-align: center;
      line-height: 19px;
      padding: 11px 0;
      background-image: linear-gradient(85deg, rgb(16, 66, 49) 0%, rgba(16, 66, 49, 0.6) 100%)
    }
  }
  .InviteFriend__botbox{
    background-color: rgb(249, 249, 249);
    border-radius: 20px 20px 0 0;
    padding: 20px;
    .myIncome{
      background-color: $white-color;

      .myIncome__title{
        font-size: 20px;
        font-weight: 600;
        color: $blackFont-color;
        line-height: 23px;
        margin-bottom: 20px;
      }

      .myIncome__box{
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        font-size: 14px;
        font-weight: 400;
        color: rgb(131, 131, 131);
        line-height: 14px;
        height: 71px;

        .myIncome__box__title{
          width: 160px;
        }

        .rightBlack{
          display: inline-block;
        }

        .myIncome__usdt{
          font-size: 14px;
          font-weight: bold;
          color: $blackFont-color;
          line-height: 19px;
          margin-top: 8px;
        }
        .myIncome__bottom{
          background: $green-color;
          border-radius: 16px;
          padding: 15px 10px;
          .bottom__font{
            color: $white-color;
            opacity: 0.5;
          }
          .myIncome__usdt{
            color: $white-color;
          }

        }
      }

      .myIncome__link{
        background-color: rgb(233, 240, 238);
        padding: 16px;
        border-radius: 16px;
        .myIncome__link__title{
          font-size: 15px;
          font-weight: 400;
          color: $blackFont-color;
          line-height: 15px;
          margin-bottom: 5px;
        }

        .adm-button{
          background-color: rgb(233, 240, 238);
          border: none;
        }
      }
    }
  }

}

.inviteTable{
  width: -webkit-fill-available;
  border-spacing: 0;
  .s1{
    color: rgb(36, 105, 82);
  }
  .s2{
    color: rgb(102, 70, 25);
  }
  .s3{
    color: rgb(193, 108, 56);
  }
  .s4{
    color: rgb(232, 120, 53);
  }
  .s5{
    color: rgb(31, 31, 31);
  }
  .s6{
    color: rgb(200, 250, 0);
  }
  .border-top-left-radius{
    border-top-left-radius: 8px;
  }
  .border-top-right-radius{
    border-top-right-radius: 8px
  }
  thead{
    tr{
      color: #fff;
      background-color: rgb(36, 105, 82);
      height: 40px;
    }
  }
  tbody{
    tr{
      height: 40px;
    }
    tr:nth-child(2n+1) {
      background-color: rgb(242, 241, 237);;
    }
    
  }
}
