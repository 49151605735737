@import '_variables.scss';
.Defi{
  background-color: $green-color;
  .Defi__greenBox{
    padding: 0 20px 24px 20px;
    .Defi__imgBox{
      width: 100%;
      height: 140px;
      background-color: $white-color;
      border-radius: 12px;
      padding: 24px 0 0 20px;
      margin-bottom: 16px;
      .imgBox__left{
        height: 116px;
        .imgBox__left__bold{
          font-weight: 700;
          font-size: 20px;
          color: rgb(16, 66, 49);
          margin: 4px 0 12px 0;
        }
        .imgBox__left__btn{
          width: 96px;
          height: 28px;
          border-radius: 12px;
          background-color: $green-color;
          text-align: center;
          line-height: 28px;
          color: $white-color;
        }
      }
    }
    .bgGreenAsh__box{
      height: 114px;
      background: rgb(58, 120, 99);
      border-radius: 12px;
      color: $white-color;
      padding-top: 16px;
      .marginBottom{
        margin-bottom: 4px;
      }
      .Defi__num{
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 8px;
        overflow-wrap: break-word;
      }
      .flex__space__center{
        font-size: 14px;
        font-weight: 700;
        .imgClass{
          margin-left: 4px;
        }
      }
    }
    .left__box{
      margin-right: 8px;
      width: 100%;
    }

  }

  .Defi__bottom{
    background-color: rgb(242, 241, 237);
    border-radius: 16px 16px 0 0;
    min-height: 100vh;
    color: rgb(31, 31, 31);
    padding: 24px 20px 40px 20px;
    .bot__title{
      font-weight: 700;
      margin-bottom: 16px;
      font-size: 20px;
    }
    .card__box{
      background-color: $white-color;
      padding: 0 16px 24px 16px;

      .card__title{
        width: 100%;
        line-height: 32px;
      }

      .Defi__box {
        .Defi__box__Polyline {
          width: 160px;
  
          .Defi__box__Polyline__Img {
            margin-right: 8px;
            width: 52px;
            height: 32px;
            position: relative;
  
            .Defi__box__Polyline__Img1 {
              position: absolute;
              right: 0;
              top: 5px;
            }
  
            .Defi__box__Polyline__Img2 {
              position: absolute;
              right: 7px;
              top: 5px;
            }
  
            .Defi__box__Polyline__Img3 {
              position: absolute;
              right: 14px;
              top: 5px;
            }
  
            .Defi__box__Polyline__Img0 {
              position: absolute;
              right: 21px;
              z-index: 1;
            }
          }
  
          .adm-image {
            display: inline-block;
          }
  
          .Defi__box__title {
            width: 100px;
            font-size: 14px;
            font-weight: 700;
            color: $blackFont-color;
          }
          .Defi__box__quota{
            width: 100px;
            font-size: 14px;
            color: $blackFont-color;
          }
  
          .Defi__box__key {
            align-items: center;
            display: flex;
            width: 100%;
          }
        }
        .rightBox{
          flex: 1 1 0%;
          text-align: right;
        }
  
        .Defi__APY{
          width: 100%;
          margin-bottom: 10px;
          .Defi__APY__key {
            font-size: 24px;
            font-weight: 700;
            color: $green2-color;
          }
    
          .Defi__APY__value {
            font-size: 18px;
            font-weight: 700;
            color: $blackFont-color;
          }
        }
  
        .fontDay{
          display: flex;
    text-align: left;
    width: 100%;
    margin-left: 20px;
    margin-bottom: 20px;
        }
        .Defi__TVL {
          width: 100%;
          font-size: 14px;
          color: $blackFont-color;
          font-weight: 700;
        }
        .Defi__btnBox{
          width: 100%;
          display: flex;
          justify-content: flex-end;
          text-align: right;
          .Defi__btn{
            width: 100px;
            height: 40px;
            border-radius: 16px;
            background-color: $black-color;
            text-align: center;
            color: $white-color;
            line-height: 40px;
          }
        }
      }
    }
  }
}