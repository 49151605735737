@import '_variables.scss';
.Withdraw{
  .searchBox{
    padding: 24px 0;
    .searchIcon{
      height: 40px;
      position: relative;
      margin: auto;
      .searchIcon__span{
        position: absolute;
        top: 12px;
        left: 80px;
        width: 20px;
        height: 20px;
        color: rgb(181, 181, 181);
        z-index: 1;
      }
      .searchInput{
        display: block;
        width: 327px;
        height: 40px;
        font-size: 16px;
        font-family: "PingFang SC-Semibold", "PingFang SC";
        font-weight: 600;
        color: rgb(31, 31, 31);
        text-align: center;
        line-height: 19px;
        margin: 24px auto;
        background: rgb(246, 246, 246);
        border-radius: 40px;
        border: 1px solid rgb(245, 245, 247);
        outline: none;
        box-shadow: none;
      }
    }
  }
  .WalletNet__bot {
    background-color: $white-color;
    height: calc(-318px + 100vh);
    padding: 0 20px;
    border-radius: 24px 24px 0px 0px;
    .list__usdt{
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding: 16px 0px;
      border-bottom: 1px solid rgba(31, 31, 31, 0.04);
      .list__left{
        display: flex;
        align-items: center;
        span{
          margin-left: 10px;
          color: rgb(129, 129, 129);
        }
        .flex__direction{
          .USDT{
            font-size: 16px;
            font-weight: 600;
            color: $blackFont-color;
            line-height: 19px;
            margin-bottom: 2px;
          }
        }
      }
      .list__right{
        text-align: right;
        div{
          font-size: 16px;
          font-weight: bold;
          color: rgb(31, 31, 31);
          line-height: 19px;
          margin-bottom: 2px;
        }
        span{
          font-size: 14px;
          font-weight: 400;
          color: rgb(31, 31, 31);
          line-height: 14px;
        }
      }
    }
  }
}
.modal__linkWithdraw{
  line-height: 40px;
  padding: 14px 0px;
    font-size: 14px;
    font-weight: 600;
    color: rgb(31, 158, 115);
    background: rgb(246, 246, 246);
    border-radius: 8px;
    text-align: center;
    margin-bottom: 8px;
}
.modal__urlWithdraw{
  padding: 6px 0 5px;
  font-size: 14px;
    font-weight: 500;
    color: rgb(247, 147, 26);
    line-height: 40px;
    background: rgb(246, 246, 246);
    border-radius: 8px;
    text-align: center;
}