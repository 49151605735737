@import '_variables.scss';
.Index__header {
  // width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .headerBox__Btn {
    height: 30px;
    .headerBox__Btn__connect {
      margin-right: 20px;
    }
    .headerBox__Btn__noConnect{
      height: 30px;
      background-color: $green1-color;
      border-radius: 12px;
      padding: 0 4px;
      display: flex;
      align-items: center;
      color: $white-color;
      .headerBox__Btn__BSC{
        font-size: 14px;
        color: $green-color;
        background-color: $white-color;
        border-radius: 12px;
        line-height: 20px;
        padding: 0 4px;
        margin-right: 2px;
      }
    }

  }
}
.wallet__Modal{
    position: relative;
    font-weight: 600;
    font-size: 16px;
    color: $black-color;
  .wallet__Modal__title{
    text-align: center;
    padding-bottom: 20px;
  }
  .wallet__Modal__link{
    display: flex;
    align-items: center;
    justify-content: center;
    .wallet__Modal__or{
      margin: 0 8px;
    }
  }
}
.maskModal__dom{
  position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 99;
    background: #fff;
    opacity: 0.5;
}