@import '_variables.scss';
.OnlineRecharge{
  .OnlineRecharge__box{
    padding: 20px;
    .list__left{
      display: flex;
      align-items: center;
      span{
        margin-left: 10px;
        color: rgb(129, 129, 129);
      }
      .flex__direction{
        .USDT{
          font-size: 16px;
          font-weight: 600;
          color: $blackFont-color;
          line-height: 19px;
          margin-bottom: 2px;
        }
      }
    }
    .card__box{
      border: 1px solid rgb(238, 238, 238);
      padding: 20px;
      background-color: $white-color;
      .card__box__input{
        background-color: $cardBG-color;
        line-height: 40px;
        padding: 4px 11px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 6px;
        .adm-list-item{
          background-color: $cardBG-color;
          .adm-input-element{
            font-size: 18px;
            font-weight: 700;
            color: $black-color;
          }
        }
        .form__USDT{
          color: $black-color;
        }
      }
    }
  }
}