@import '_variables.scss';
.DefiPay{
  background-color: $green-color;
  .DefiPay__box{
    padding: 0 20px 24px 20px;
    font-size: 14px;
    .DefiPay__card{
      background: linear-gradient(rgb(207, 248, 234) 0%, rgb(255, 255, 255) 100%);
      border-radius: 12px;
      padding: 20px 20px 10px 20px;
      position: relative;

      .BTCIconBox{
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
  .DefiPay__bot{
    background-color: $cardBG-color;
    border-radius: 12px 12px 0 0;
    padding: 20px;
    .card__box{
      background-color: $white-color;
      padding-top: 24px;
      padding-left: 20px;
      padding-right: 20px;
      .Stepperbox{
        width: 100%;
        position: relative;
        padding-bottom: 20px;
        .T{
          position: absolute;
          font-weight: 700;
          font-size: 18px;
          line-height: 48px;
          z-index: 1;
          right: 60px;
        }
        .adm-stepper-input{
          .adm-input-element{
            text-align: left;
            padding-left: 10px;
          }
        }
      }
      .StepperNum{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 24px;
        .NumBtn{
          background-color: rgb(246, 246, 246);
          line-height: 32px;
          width: 88px;
          text-align: center;
          border-radius: 6px;
          margin-bottom: 16px;
        }
        .btndetail{
          background-color: $white-color;
          display: flex;
          align-items: center;
          font-size: 14px;
        }
      }
      .aling__center{
        background-color: rgb(246, 246, 246);
        padding-left: 16px;
        border-radius: 6px;
        height: 48px;
      }

      .transition180{
        transition: all 0.2s ease 0s; transform: rotate(180deg);
      }
      .transition0{
        transition: all 0.2s ease 0s; transform: rotate(0deg);
      }
      .Income__center{
        display: flex;
  align-items: center;
      }
      .Slider__name{
        --fill-color: #1f9e73;
        .adm-slider-fill{
          height: 6px;
        }
        .adm-slider-track{
          height: 6px;
        }
        .adm-slider-thumb-container{
          width: 18px;
          height: 18px;
        }
        .adm-slider-thumb{
          width: 18px;
          height: 18px;
          border: 2px solid #1f9e73;
          margin: 0;
        }
      }
      .DefiPay__nowbtn{
        text-align: center;
        font-size: 16px;
        background-color: $green-color;
        line-height: 44px;
        border-radius: 12px;
        color: $white-color;
        margin-top: 32px;
      }
    }
  }

}

.modal__goDeposit{
  border-radius: 6px;
  text-align: center;
  background: rgb(36, 105, 82);
  line-height: 32px;
}