@import '_variables.scss';


.Announcement__box {
  color: $blackFont-color;
  margin-bottom: 24px;

  .Announcement__list {
    padding: 20px;
    overflow-y: auto;

    .Announcement__title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    .Announcement__time {
      font-size: 14px;
    }
  }
}